import Spacer from "~/components/Spacer";
import { getCommonSliceProps } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

type PropType = {
  size: number;
  backgroundColor?: string;
};

function SpacerSlice(props: PropType) {
  const { size = 1, backgroundColor } = props;

  return <Spacer backgroundColor={backgroundColor} size={size} />;
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  // console.log(primary);

  const props = {
    ...getCommonSliceProps(slice),
    size: primary.size,
  };
  return props;
}

export default connectSlice(mapSliceToProps)(SpacerSlice);
